import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import LoadingContent from "../../common/LoadingContent";
import PageTitleNoAdd from "../../components/header/PageTitleNoAdd";
import axiosWithAuth from "../../utils/axiosWithAuth";

export default function OrderConfirmationPageRestaurant() {
  const { id } = useParams();

  const [orderData, setOrderData] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  console.log("orderData", orderData);

  useEffect(() => {
    const APIURL = `/api/restaurant/order/getOrdersDetails/${id}`;

    axiosWithAuth()
      .get(APIURL)
      .then((res) => {
        console.log("resssssssssssss", res.data.data.order);
        setOrderData(res.data.data.order);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setError(err);
      });
  }, [id]);

  return (
    <div id="order-confirmation">
      <Helmet>
        <title>BIZZLL - Order Confirmation</title>
      </Helmet>

      <PageTitleNoAdd pageTitle="Order Confirmation" />

      <div className="page-wrapper">
        {loading ? (
          <LoadingContent />
        ) : (
          <div className="inner-container">
            <div className="header">
              <h2>Thank you for your order!</h2>

              <h3>Order Number: {orderData.id}</h3>
            </div>

            <div className="order-details">
              <h3>Order Details</h3>

              <div className="products-table">
                <div className="row labels">
                  <div className="item">Item</div>
                  <div className="price">Price</div>
                  <div className="quantity">Quantity</div>
                  <div className="tax">Service Fee</div>
                  <div className="total">Total</div>
                </div>

                {orderData.detail.map((item) => {
                  console.log("**Item**");
                  console.log(item);
                  let productName;
                  let productDescription;
                  const fullPrice = item.total_price * 1.1;

                  productName = item.dish_name || "Unknown";
                  productDescription = item.dish_description || "Unknown";

                  return (
                    <div className="row items" key={item.id}>
                      <div className="item">
                        {productName}
                        <span>{productDescription}</span>
                      </div>
                      <div className="price">${item.dish_price.toFixed(2)}</div>
                      <div className="quantity">{item.dish_quantity}</div>
                      <div className="tax">10%</div>
                      <div className="total">
                        ${item.dish_price.toFixed(2) * item.dish_quantity}
                      </div>
                    </div>
                  );
                })}
              </div>

              <h3>Total: ${orderData.total_price_order}</h3>
            </div>
          </div>
        )}

        {error !== "" && (
          <div className="inner-container">
            <div className="header">
              <h2>There was an error retrieving order</h2>

              <h3>{error}</h3>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
